import React , { useState , useEffect} from "react"
import { FaGraduationCap, FaUsers } from "react-icons/fa"
import { GiEvilBook, GiWorld } from "react-icons/gi"
import { Link } from "react-router-dom";


export const Instructor = () => {

  const [instructors, setInstructors] = useState([]);
  const logedin = JSON.parse(localStorage.getItem('user'));


  useEffect(() => {

    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch('https://unih0me.com/api/teachers');
      const data = await response.json();
      setInstructors(data.data.teachers);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <section className="instructor mb-16">
        <div className="container">
          <div className="heading py-12 text-center w-2/3 m-auto md:w-full">
            <h1 className="text-3xl font-semibold text-black">
              What Is Skilline?
            </h1>
            <span className="text-[14px] mt-2 block">
              Sloshed faff about me old mucker blatant bubble and squeak hanky
              panky some dodgy chav bevvy arse chimney pot I, ruddy plastered
              buggered smashing blow off I'm telling up the kyver he legged it
              bleeder jolly good,
            </span>
          </div>
          {/* Start of instructor cards */}
          <div className="instructors_cards">
            {instructors.map((instructor) => (
                <div className="instructor_card">
                <div className="image">
                  <Link to={`/instructor/${instructor.id}`}>
                  <img src={instructor.image} alt="logo" width={160} height={160} />
                  </Link>
                  <span>Starts From</span>
                  <h2>EGP{" "}{instructor.balance}</h2>
                </div>
                <div className="content">
                  <div className="name">
                    <h3>{instructor.name}</h3>
                    <div className="icons">
                      <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i>
                    </div>
                    
                    <span className="reviews">{instructor.review} Reviews</span>
                  </div>
  
                  <div className="instructor_info">
                    <p>Teaches : <span>{instructor.course}</span></p>
                    <p>From  : <span>{instructor.start_from}</span></p>
                    <p>Phone : <span>{instructor.phone}</span></p>
                    <p>level : <span>{instructor.level}</span></p>
                  </div>
                  <p className="description">{instructor.intro}</p>
  
                  <div className="actions">
                    
                    <Link to="#" className="btn"><i class="fa-regular fa-calendar-days"></i>{" "}Availability</Link>
                    <Link to="#" className="btn"><i class="fa-solid fa-envelope"></i>{" "}Message</Link>
                  </div>
                </div>
              </div>
              ))}
          </div>
          {/* End of instructor cards */}
          <div className="content">
            <div className="heading py-12 text-center w-2/3 m-auto md:w-full">
              <h1 className="text-3xl font-semibold text-black">
                We Are Proud
              </h1>
              <span className="text-[14px] mt-2 block">
                You don't have to struggle alone, you've got our assistance and
                help.
              </span>
            </div>
            <div className="content grid grid-cols-4 gap-5 md:grid-cols-2">
              <InstructorCard
                color="text-red-500"
                icon={<FaUsers size={40} />}
                title="63"
                desc="Students Enrolled"
              />
              <InstructorCard
                color="text-orange-500"
                icon={<GiEvilBook size={40} />}
                title="20"
                desc="Total Courses"
              />
              <InstructorCard
                color="text-purple-500"
                icon={<FaGraduationCap size={40} />}
                title="4"
                desc="Online Learners"
              />
              <InstructorCard
                color="text-indigo-500"
                icon={<GiWorld size={40} />}
                title="4"
                desc="Online Learners"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export const InstructorCard = (props) => {
  return (
    <div className={`box p-5 py-5 rounded-md`}>
      <div className={`${props.color}`}>{props.icon}</div>
      <div className='text mt-2'>
        <h4 className='text-lg font-semibold text-black'>{props.title}</h4>
        <p className='text-[15px]'>{props.desc}</p>
      </div>
    </div>
  )
}
