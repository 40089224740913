import React, { useEffect, useState } from "react";
import {
  FaStar,
  FaFlag,
  FaChalkboardTeacher,
  FaUserGraduate,
  FaLanguage,
  FaTwitter,
  FaLinkedin,
  FaFacebook,
} from "react-icons/fa";
import {
  AiOutlineMessage,
  AiOutlineBook,
  AiOutlineEdit,
  AiOutlineDelete,
} from "react-icons/ai";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

const TutorCard = () => {
  const [teacher, setTeacher] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const teacher_id  = useParams().id;
  console.log(teacher_id);
  
  
  const token = JSON.parse(localStorage.getItem("user")).access_token;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`https://unih0me.com/api/teacher/${teacher_id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        setTeacher(data.data.user);
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [teacher_id, token]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  console.log(teacher);
  
  return (
    <div className="bg-white shadow-lg rounded-lg overflow-hidden p-3 sm:p-4 md:p-5 lg:p-6 animate-fade-in max-w-3xl mx-auto my-4 sm:my-6 md:my-8">
      <div className="flex flex-col lg:flex-row">
        <div className="flex flex-col items-center lg:items-start">
          <Link >
            <img
              className="w-24 h-24 sm:w-28 sm:h-28 md:w-32 md:h-32 rounded-sm object-cover shadow-md mb-4 lg:mb-0"
              src={teacher?.image}
              alt="Tutor"
            />
          </Link>
          <div className="w-full lg:ml-0 lg:mr-6">
            <div className="flex flex-col lg:flex-row justify-between items-center lg:items-start">
              <div className="text-center lg:text-left">
                <div className="flex items-center justify-center lg:justify-start flex-wrap mt-3">
                  <span className="text-xs bg-green-500 text-white px-2 py-1 rounded-md mr-2 mb-2 lg:mb-0">
                    FEATURED
                  </span>
                  <h2 className="text-xl sm:text-2xl font-bold">{teacher?.name}</h2>
                  <FaFlag className="ml-2 text-blue-500" />
                </div>
                <p className="text-sm sm:text-base text-gray-500 flex items-center justify-center lg:justify-start mt-1 sm:mt-2">
                  <FaChalkboardTeacher className="mr-1" /> Egypt
                </p>
                <p className="text-sm sm:text-base text-gray-500 flex items-center justify-center lg:justify-start mt-1 sm:mt-2">
                  <FaUserGraduate className="mr-1" /> Lessons {teacher?.lessons} Students {teacher?.students}
                </p>
                <p className="text-sm sm:text-base text-gray-500 flex items-center justify-center lg:justify-start mt-1 sm:mt-2">
                  <FaLanguage className="mr-1" /> {teacher?.languages}
                </p>
              </div>
              <div className="text-center lg:text-right mt-3 lg:mt-0">
                {/* <div className="flex items-center justify-center lg:justify-end">
                  {[...Array(teacher?.rating)].map((_, i) => (
                    <FaStar key={i} className="text-yellow-500" />
                  ))}
                  <span className="ml-1 text-lg sm:text-xl font-bold">{teacher?.rating}</span>
                </div> */}
                {/* <p className="text-xs sm:text-sm text-gray-500">Ratings: {teacher?.ratingsCount}</p>
                <p className="text-lg sm:text-xl font-bold mt-1 sm:mt-2">
                  £{teacher?.rate} per hour
                </p> */}
              </div>
            </div>
            <p className="text-sm sm:text-base text-gray-700 mt-3 sm:mt-4 text-center lg:text-left">
              {teacher?.description}
            </p>
            <div className="flex items-center justify-center lg:justify-start mt-3 sm:mt-4">
              <FaTwitter className="text-blue-500 mr-3 sm:mr-4 cursor-pointer" />
              <FaLinkedin className="text-blue-700 mr-3 sm:mr-4 cursor-pointer" />
              <FaFacebook className="text-blue-600 cursor-pointer" />
            </div>
            <div className="mt-4 sm:mt-5 md:mt-6 grid grid-cols-2 sm:flex sm:flex-wrap gap-2 sm:gap-3 md:gap-4">
              <Link to={`/calendar/${teacher_id}`}>
                <button className="bg-green-500 text-white px-3 sm:px-4 py-2 rounded-lg text-sm sm:text-base font-medium transform hover:scale-105 transition-transform duration-300 flex items-center justify-center">
                  <AiOutlineBook className="mr-1" />
                  Book Now
                </button>
              </Link>
              <Link to={`/chat/${teacher_id}`}>
                <button className="bg-blue-500 text-white px-3 sm:px-4 py-2 rounded-lg text-sm sm:text-base font-medium transform hover:scale-105 transition-transform duration-300 flex items-center justify-center">
                  <AiOutlineMessage className="mr-1" />
                  Message
                </button>
              </Link>
              {/* <button className="bg-orange-500 text-white px-3 sm:px-4 py-2 rounded-lg text-sm sm:text-base font-medium transform hover:scale-105 transition-transform duration-300 flex items-center justify-center">
                <AiOutlineEdit className="mr-1" />
                Edit
              </button> */}
            </div>
            <div className="mt-6 sm:mt-7 md:mt-8">
              <h3 className="text-base sm:text-lg font-semibold mb-3 sm:mb-4 text-center lg:text-left">
                Reviews
              </h3>
              <div className="space-y-3 sm:space-y-4">
                {teacher?.reviews?.map((review, index) => (
                  <div
                    key={index}
                    className="p-3 sm:p-4 bg-gray-100 rounded-lg shadow-sm"
                  >
                    <div className="flex items-center justify-center lg:justify-start">
                      {[...Array(review.rating)].map((_, i) => (
                        <FaStar
                          key={i}
                          className="text-yellow-500 text-sm sm:text-base"
                        />
                      ))}
                      <span className="ml-2 text-xs sm:text-sm font-medium">
                        by {review.name}
                      </span>
                    </div>
                    <p className="text-xs sm:text-sm text-gray-700 mt-2 text-center lg:text-left">
                      {review.comment}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TutorCard;
