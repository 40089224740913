import React, { useState, useEffect } from "react";
const Student = () => {
  const [errors, setErrors] = useState({});



  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://unih0me.com/api/auth/register/teacher', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: e.target.name.value,
          email: e.target.email.value,
          password: e.target.password.value,
          password_confirmation: e.target.password_confirmation.value,
          phone: e.target.phone.value,
          balance: 0,
          start_from: new Date().toISOString().split('T')[0],
          type: e.target.type.value,
          course_id: e.target.course_id.value,
          level_id: e.target.level_id.value,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to register");
      }

      const data = await response.json();
      console.log(data);
      localStorage.setItem('user', JSON.stringify(data.data));
      window.location.href = "/";
    } catch (error) {
      console.error('Error:', error);
      setErrors(error.response ? error.response.data.errors : { general: 'An error occurred' });
    }
  };

  return (
    <div className="font-[sans-serif]">
      <div className="grid lg:grid-cols-3 md:grid-cols-2 items-center h-full">
        <div className="max-md:order-1 lg:col-span-2 md:h-[100%] w-full bg-[#000842] md:rounded-tr-xl md:rounded-br-xl lg:p-12 p-8">
          <img
            src="https://readymadeui.com/signin-image.webp"
            className="lg:w-[100%] w-full h-full object-cover block mx-auto"
            alt="register-image"
          />
        </div>
        <div className="w-full p-6">
          <form onSubmit={handleSubmit}>
            <div className="mb-8">
            <h3 className="text-gray-800 text-3xl font-extrabold">
        Register as a Student
      </h3>
              <p className="text-sm mt-4 text-gray-800">
                Already have an account?
                <a
                  href="/login"
                  className="text-blue-600 font-semibold hover:underline ml-1 whitespace-nowrap"
                >
                  Sign in here
                </a>
              </p>
            </div>

            <div>
              <label className="text-gray-800 text-[15px] mb-2 block">Name</label>
              <div className="relative flex items-center">
                <input
                  name="name"
                  type="text"
                  className="w-full text-sm text-gray-800 bg-gray-100 focus:bg-transparent px-4 py-3.5 rounded-md outline-blue-600"
                  placeholder="Enter your name"
                />
                {errors.name && <span className="text-red-600">This field is required</span>}
              </div>
            </div>

            <div className="mt-4">
              <label className="text-gray-800 text-[15px] mb-2 block">Email</label>
              <div className="relative flex items-center">
                <input
                  name="email"
                  type="email"
                  className="w-full text-sm text-gray-800 bg-gray-100 focus:bg-transparent px-4 py-3.5 rounded-md outline-blue-600"
                  placeholder="Enter your email"
                />
                {errors.email && <span className="text-red-600">This field is required</span>}
              </div>
            </div>

            <div className="mt-4">
              <label className="text-gray-800 text-[15px] mb-2 block">Phone</label>
              <div className="relative flex items-center">
                <input
                  name="phone"
                  type="text"
                  className="w-full text-sm text-gray-800 bg-gray-100 focus:bg-transparent px-4 py-3.5 rounded-md outline-blue-600"
                  placeholder="Enter your phone"
                />
                {errors.phone && <span className="text-red-600">This field is required</span>}
              </div>
            </div>

             <input type="hidden" name="type" value="student" />

           {/*<div className="mt-4">
              <label className="text-gray-800 text-[15px] mb-2 block">Type</label>
              <div className="relative flex items-center">
                <select
                  name="type"
                  className="w-full text-sm text-gray-800 bg-gray-100 focus:bg-transparent px-4 py-3.5 rounded-md outline-blue-600"
                >
                  <option value="student">Student</option>
                  <option value="teacher">Teacher</option>
                </select>
                {errors.type && <span className="text-red-600">This field is required</span>}
              </div>
            </div>

            <div className="relative flex items-center mt-4">
              <select
                name="course_id"
                className="w-full text-sm text-gray-800 bg-gray-100 focus:bg-transparent px-4 py-3.5 rounded-md outline-blue-600"
              >
                <option value="">Select Course</option>
                {courses.map((course) => (
                  <option key={course.id} value={course.id}>{course.title}</option>
                ))}
              </select>
              {errors.course_id && <span className="text-red-600">This field is required</span>}
            </div>

            <div className="relative flex items-center mt-4">
              <select
                name="level_id"
                className="w-full text-sm text-gray-800 bg-gray-100 focus:bg-transparent px-4 py-3.5 rounded-md outline-blue-600"
              >
                <option value="">Select Level</option>
                {levels.map((level) => (
                  <option key={level.id} value={level.id}>{level.title}</option>
                ))}
              </select>
              {errors.level_id && <span className="text-red-600">This field is required</span>}
            </div>*/}

            <div className="mt-4">
              <label className="text-gray-800 text-[15px] mb-2 block">Password</label>
              <div className="relative flex items-center">
                <input
                  name="password"
                  type="password"
                  className="w-full text-sm text-gray-800 bg-gray-100 focus:bg-transparent px-4 py-3.5 rounded-md outline-blue-600"
                  placeholder="Enter your password"
                />
                {errors.password && <span className="text-red-600">This field is required</span>}
              </div>
            </div>

            <div className="mt-4">
              <label className="text-gray-800 text-[15px] mb-2 block">Confirm Password</label>
              <div className="relative flex items-center">
                <input
                  name="password_confirmation"
                  type="password"
                  className="w-full text-sm text-gray-800 bg-gray-100 focus:bg-transparent px-4 py-3.5 rounded-md outline-blue-600"
                  placeholder="Confirm your password"
                />
                {errors.password_confirmation && <span className="text-red-600">This field is required</span>}
              </div>
            </div>

            <div className="mt-8">
              <button
                type="submit"
                className="w-full py-3 px-6 text-sm tracking-wide rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none"
              >
                Register
              </button>
            </div>

            <div className="my-4 flex items-center gap-4">
              <hr className="w-full border-gray-300" />
              <p className="text-sm text-gray-800 text-center">or</p>
              <hr className="w-full border-gray-300" />
            </div>

            <button
              type="button"
              className="w-full flex items-center justify-center gap-4 py-3 px-6 text-sm tracking-wide text-gray-800 border border-gray-300 rounded-md bg-gray-50 hover:bg-gray-100 focus:outline-none"
            >
              <span>Continue with Google</span>
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Student;
