import React, { useState } from "react"
import LogoImg from "../assets/images/UNI_LOGO-removebg-preview.png";
import { LinkData } from "../assets/data/dummydata";
import { Link, NavLink } from "react-router-dom";
import { HiOutlineMenuAlt1} from "react-icons/hi";
import { Dropdown, DropdownToggle, DropdownMenu,DropdownButton, DropdownItem } from 'react-bootstrap';
export const Header = () => {
  window.addEventListener('DOMContentLoaded', () => {
var user2=localStorage.getItem('user');
var user="";
    if (user2) {
         user=JSON.parse(user2) ;
document.querySelector(".user").innerHTML="<img src='./images/student.png' />"

const logedin = localStorage.getItem("user");
    }
})
  const [open, setOpen] = useState(false);

  const logout = () => {
    localStorage.removeItem("user");
    window.location.reload();
    window.location.href = "/";
  }
  /*const [userType, setUserType] = useState(null);

  function RegisterDropdownMenu({ setUserType }) {
    return (
      <Dropdown>
        <DropdownToggle className='register ml-3'>Register</DropdownToggle>
        <Dropdown.Menu>
          <Dropdown.Item as={Link} to="/register" onClick={() => {
            setUserType('Student');
            localStorage.setItem('userType', 'Student');
          }}>as a Student</Dropdown.Item>
          <Dropdown.Item as={Link} to="/register" onClick={() => {
            setUserType('Teacher');
            localStorage.setItem('userType', 'Teacher');
          }}>as a Teacher</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  }*/

  return (
    <>
      <header className="bg-white py-2 text-black sticky z-50 shadow-md top-0 left-0 w-full">
        <div className="container flex justify-between items-center">
          <div className="logo flex items-center ">
            <Link to="/">
              <img src={LogoImg} alt="logo" width={70} height={70} />
            </Link>'
            <span className="text-xl font-bold text-orange-500">UNIHOME</span>
            
            {/* <div className="category flex items-center text-sm gap-3">
              <HiViewGrid size={20} />
              <span>category</span>
              </div> */}
          </div>
          <nav className={open ? "mobile-view" : "desktop-view"}>
            <ul className="flex items-center gap-6 ml-5">
              {LinkData.map((link) => (
                <li key={link.id} onClick={() => setOpen(null)}>
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "text-sm  text-orange-500"  : "text-[15px]"
                    }
                    to={link.url}
                    
                  >
                    {link.title}
                  </NavLink>
                </li>
              ))}
            </ul>
          </nav>
          <div className="account flex items-center gap-4">
            {
              (!localStorage.getItem('user'))?
              <div className="user d-flex gap-3 justify-center align-items-center">
                <Link to="/login">
              <button className='login'>Login</button>
            </Link>

            <Dropdown>  
  <Dropdown.Toggle variant=" primary" id="dropdown-basic" className="register ml-3">  
    Register  
  </Dropdown.Toggle>  
  
  <Dropdown.Menu>  
  <Dropdown.Item as={Link} to="/register/teacher">
        As a Teacher
      </Dropdown.Item>
      <Dropdown.Item as={Link} to="/register/student">
        As a Student
      </Dropdown.Item>
  </Dropdown.Menu>  
</Dropdown>  
              </div>
              :
              <div className="d-flex gap-3 ">
                <Link><button onClick={logout} className='logout'>Logout</button></Link>
                <Link><button className='profile'>Profile</button></Link>
              </div>
            }
            <button className="open-menu" onClick={() => setOpen(!open)}>
              <HiOutlineMenuAlt1 size={25} />
            </button>
          </div>
        </div>
      </header>
    </>
  );
};
